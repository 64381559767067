import React, {useState, useEffect} from 'react'
import Button from './Button';
import { Link, useNavigate } from 'react-router-dom';
import { useGSAP } from '@gsap/react';
import gsap from 'gsap';

function MainSection({videoSrc, homeSrc, main_head, sub_head, isOpen, setOpen, menuItems, showBtn = true}) {
    const [videoReady, setVideoReady] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);
    const history = useNavigate()
  
    function handleDataFromChild(data) {
      history('/live-streams')
    }

    const handleCanPlay = () => {
      setIsSmallScreen(window.innerWidth < 576);
    };
  
    const handleResize = () => {
      setVideoReady(window.innerWidth > 576)
      if(window.innerWidth > 576){
        setOpen(window.innerWidth < 576)
      }
    }
  
    useEffect(() => {
      if (!isSmallScreen) {
        setVideoReady(true);
      } else {
        setVideoReady(false);
      }
    }, [isSmallScreen]);
  
    useEffect(() => {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    useGSAP(() =>{
      const mainHead = document.querySelector('#main_head');
      const subHead = document.querySelector('#sub_head');
      const animeElements = document.querySelectorAll('.anime');
      const tl = gsap.timeline({});
      if (mainHead) {
        tl.from(mainHead, {
          opacity: 0,
          y: -500,
          duration: 1
        });
      }
  
      if (subHead) {
        tl.from(subHead, {
          opacity: 0,
          y: 500,
          duration: 1
        });
      }
  
      if (animeElements.length > 0) {
        tl.from(animeElements, {
          opacity: 0,
          duration: 1
        });
      }
  
    })
  return (
    <div className='video_div' style={{
      height: !showBtn && !isSmallScreen ? '45vh' : undefined,
      }}>
        {(!videoReady || videoSrc === '' ) && (
        <img className={`d-block ${videoSrc === '' ? '' : 'd-sm-none'}`}
            src={homeSrc}
            alt="Placeholder"
            style={{ width: '100%', height: '100%' }}
            loading="lazy"
        />
        )}
        {videoSrc !== '' && ( <video className='d-none d-sm-block'
          style={{ display: videoReady ? 'block' : 'none', width: '100%' }}
          loop
          autoPlay
          muted
          onCanPlay={handleCanPlay}
          >
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
          </video>
        )}
        <div className="content">
          <h2 id='main_head'>{main_head}</h2>
          <h3 id="sub_head">{sub_head}</h3>
          {
            showBtn && <div className='mt-3'>
            <Button name="Live Streams" color="white" bgColor="transparent" hide="false" newCls="anime" eventToParent={handleDataFromChild}/>
          </div>
          }
        </div>
        <div className="overlay"></div>
        {
        <div className={`mobile ${isOpen ? 'open' : ''}`}>
         <ul className='navbar-nav mr-auto flex-col justify-content-between'>
              {
                menuItems.map((menu,index)=>{
                  return <li className={`nav-item p-1 ${menu?.subMenu?.length === 0 && 'dropdown'}`} key={index}>
                    {
                       menu?.subMenu?.length === undefined ? (
                        <Link className='nav-link' to={menu?.url}>{menu?.title}</Link>
                      ) : (
                        <>
                            <Link 
                                className="nav-link dropdown-toggle" 
                                href="#"
                                id="navbarDropdown"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"   
                            >
                              {menu?.title}
                            </Link>
                            <ul className="dropdown-menu subChild" aria-labelledby="navbarDropdown"> 
                                {
                                  menu?.subMenu.map((sub,ind)=>{
                                    return <li className='nav-item p-1' key={ind}><Link className="nav-link" to={sub?.url}>{sub?.title}</Link></li>
                                  })
                                }
                            </ul>
                        </>
                    )
                    }
                    </li>
                })
              }
            </ul>
        </div>
      }
    </div>    
  )
}

export default MainSection